import React from 'react';
import { Layout, SEO, Box, Text } from 'components';
import theme from 'styles/theme';

const NotFoundPage = () => (
  <Layout>
    <SEO title="B-Reel - 404" />
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ height: `calc(100vh - ${theme.headerHeight}px)` }}
    >
      <Text size="3x">404</Text>
      <Text color="blackDimmed">This page does not exist</Text>
    </Box>
  </Layout>
);

export default NotFoundPage;
